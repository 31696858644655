<template>
	<v-container class="owner pb-16">
		<v-row>
			<v-col cols="12" class="mt-3 mb-3">
        <h2 class="text-h4">{{ id === 'new' ? 'Додати' : 'Редагувати' }} оператора</h2>
      </v-col>
      <v-col cols="12">
        <v-form @submit.prevent="submitOwner">
          <v-row no-gutters>
            <v-col cols="12" class="mb-5">
              <v-text-field v-model="owner.name" label="Ім'я" outlined hide-details="auto"
                            @input="$v.owner.name.$touch()" @blur="$v.owner.name.$touch()"
                            :error-messages="nameErrors" class="is-required" />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-text-field v-model="owner.phone" label="Телефон" outlined hide-details="auto"
                            @input="$v.owner.phone.$touch()" @blur="$v.owner.phone.$touch()"
                            :error-messages="phoneErrors" class="is-required" />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-text-field v-model="owner.email" label="Email" outlined hide-details="auto"
                            @input="$v.owner.email.$touch()" @blur="$v.owner.email.$touch()"
                            :error-messages="emailErrors" />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-text-field v-model="owner.note" label="Примітка" outlined hide-details="auto"
                            @input="$v.owner.note.$touch()" @blur="$v.owner.note.$touch()"
                            :error-messages="noteErrors" :counter="255" />
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" type="submit" :dark="!$v.$invalid" depressed x-large :loading="isBeingSaved" :disabled="$v.$invalid">
                {{ id === 'new' ? 'Додати' : 'Зберегти зміни' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <AppPageLoader :visible="isPageLoading" />
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { GET_OWNER, ADD_OWNER, EDIT_OWNER, CLEAR_OWNER_STATE, } from '@/store/owners/types';

export default {
  data: () => ({
    isPageLoading: false,
    owner: {
      name: '',
      phone: '',
      email: '',
      note: '',
    },
    isBeingSaved: false,
    snack: false,
    snackColor: '',
    snackText: '',
  }),
  props: ['id'],
  methods: {
    async init() {
      this.clearOwnerState();
      this.isPageLoading = true;

      if (this.id !== 'new') {
        await this.getOwner();
      }

      setTimeout(() => {
        this.isPageLoading = false;
      }, 500);
    },
    async clearOwnerState() {
      this.$store.dispatch(CLEAR_OWNER_STATE);
    },
		async getOwner() {
			await this.$store.dispatch(GET_OWNER, this.id);

      this.owner.name = this.ownerData.name;
      this.owner.phone = this.ownerData.phone;
      this.owner.email = this.ownerData.email;
      this.owner.note = this.ownerData.note;
		},
    async submitOwner() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.isBeingSaved = true;

      const owner = { ...this.owner };

      if (this.id === 'new') {
        try {
          await this.$store.dispatch(ADD_OWNER, owner);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'Оператор успішно доданий!';

          this.owner.name = '';
          this.owner.phone = '';
          this.owner.email = '';
          this.owner.note = '';

        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = 'Упс, щось пішло не так!';
        }
      } else {
        try {
          owner.id = this.id;
          await this.$store.dispatch(EDIT_OWNER, owner);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = 'Зміни успішно збережено!';
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = 'Упс, щось пішло не так!';
        }
      }
      
      this.isBeingSaved = false;
      window.scrollTo(0, 0);
    }
  },
  computed: {
    ownerData() {
			return this.$store.getters.owner;
    },
    nameErrors () {
      const errors = [];
      if (!this.$v.owner.name.$dirty) return errors;
      !this.$v.owner.name.required && errors.push('Ім\'я обов\'язкове.');
      return errors;
    },
    phoneErrors () {
      const errors = [];
      if (!this.$v.owner.phone.$dirty) return errors;
      !this.$v.owner.phone.required && errors.push('Телефон обов\'язковий.');
      !this.$v.owner.phone.phone && errors.push('Неправильний номер телефону.');
      return errors;
    },
    emailErrors () {
      const errors = [];
      if (!this.$v.owner.email.$dirty) return errors;
      !this.$v.owner.email.email && errors.push('Неправильний email.');
      return errors;
    },
    noteErrors () {
      const errors = [];
      if (!this.$v.owner.note.$dirty) return errors;
      !this.$v.owner.note.maxLength && errors.push('Перевищенно ліміт символів.');
      return errors;
    },
  },
  created() {
    this.init();
	},
  validations: {
    owner: {
      name: { 
        required,
      },
      phone: { 
        required,
        phone(value) {
          const phone = value.replace(/\D/g,'');
          return phone.length === 10;
        },
      },
      email: { 
        email,
      },
      note: {
        maxLength: maxLength(255),
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.owner {
  max-width: 600px;
}
</style>
